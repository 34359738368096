import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormLabel,
  CheckboxGroup,
  Stack,
  FormErrorMessage
} from '@chakra-ui/react'
import { ErrorMessage, useField } from 'formik'
import React, { FC, ReactNode } from 'react'
import { BaseProps } from './base-props'

export type CheckboxSingleProps = BaseProps & {
  checkboxProps?: CheckboxProps
  children?: ReactNode
  checkboxOptions?: { key: unknown; label: string }[]
}

// See https://github.com/chakra-ui/chakra-ui/discussions/3262

export const MultiCheckboxControl: FC<CheckboxSingleProps> = (
  props: CheckboxSingleProps
) => {
  const { name, label, children, checkboxOptions, checkboxProps, ...rest } =
    props
  const [field, meta] = useField(name)
  const { onChange, ...restField } = field

  return (
    <FormControl
      id={name}
      name={name}
      isInvalid={!!(meta.error && meta.touched)}
      isRequired={props.isRequired}
      {...rest}
    >
      <CheckboxGroup {...restField}>
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <Stack direction="column">
          {checkboxOptions &&
            checkboxOptions.map((opt) => (
              <Checkbox
                key={String(opt.key)}
                name={name}
                value={String(opt.key)}
                onChange={onChange}
                {...checkboxProps}
              >
                {opt.label}
              </Checkbox>
            ))}
          {children}
        </Stack>
        <ErrorMessage component={FormErrorMessage} name={name} />
      </CheckboxGroup>
    </FormControl>
  )
}
